const Dialog = ({
  show,
  children
}) => {
  return(
    <div
      className={`modal fade ${show ? "show" : ""}`}
      style={{
        display : show ? 'block' : 'none'
      }}
    >
      {children}
    </div>
  )
}

export default Dialog;