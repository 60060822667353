export const GOOGLE_REGIONS = [
  {
    label: "North America:",
    is_region_name : true
  },
  {
    label: "Montréal - northamerica-northeast1"
  },
  {
    label: "Toronto - northamerica-northeast2"
  },
  {
    label: "North Virginia - us-east4"
  },
  {
    label: "South Carolina - us-east1"
  },
  {
    label: "Ohio - us-east5"
  },
  {
    label: "Iowa - us-central1"
  },
  {
    label: "Dallas - us-south1"
  },
  {
    label: "Las Vegas - us-west4"
  },
  {
    label: "Los Angeles - us-west2"
  },
  {
    label: "Salt Lake City - us-west3"
  },
  {
    label: "Oregon - us-west1"
  },
  {
    label: "South America:",
    is_region_name : true
  },
  {
    label: "São Paulo - southamerica-east1"
  },
  {
    label: "Santiago - southamerica-west1"
  },
  {
    label: "Europe:",
    is_region_name : true
  },
  {
    label: "Finland - europe-north1"
  },
  {
    label: "Belgium - europe-west1"
  },
  {
    label: "London - europe-west2"
  },
  {
    label: "Frankfurt - europe-west3"
  },
  {
    label: "Netherlands - europe-west4"
  },
  {
    label: "Zürich - europe-west6"
  },
  {
    label: "Warsaw - europe-central2"
  },
  {
    label: "Madrid - europe-southwest1"
  },
  {
    label: "Middle East:",
    is_region_name : true
  },
  {
    label: "Tel Aviv - me-west1"
  },
  {
    label: "Doha - me-central1"
  },
  {
    label: "Africa:",
    is_region_name : true
  },
  {
    label: "Johannesburg - africa-south1"
  },
  {
    label: "Cape Town - africa-south2"
  },
  {
    label: "Asia Pacific:",
    is_region_name : true
  },
  {
    label: "Tokyo - asia-northeast1"
  },
  {
    label: "Osaka - asia-northeast2"
  },
  {
    label: "Seoul - asia-northeast3"
  },
  {
    label: "Hong Kong - asia-east2"
  },
  {
    label: "Taiwan - asia-east1"
  },
  {
    label: "Singapore - asia-southeast1"
  },
  {
    label: "Jakarta - asia-southeast2"
  },
  {
    label: "Mumbai - asia-south1"
  },
  {
    label: "Delhi - asia-south2"
  },
  {
    label: "Melbourne - australia-southeast2"
  },
  {
    label: "Sydney - australia-southeast1"
  },
  {
    label: "GCP China:",
    is_region_name : true
  },
  {
    label: "Hong Kong - asia-east2"
  }
]