import { Link } from "react-router-dom"
import Wrapper from "../Components/Wrapper"
import BillService from "../services/bills";
import Pagination from "../Components/Pagination";
import { useEffect, useState } from "react";
import { ReactComponent as Pencil } from "../assets/icons/pencil.svg";
import { ReactComponent as TrashCan } from "../assets/icons/trash-can.svg";
import Dialog from "../Components/Dialog";
import moment from "moment";

const rowsPerPage = 10;

const Bills = ({
  showSnackbar
}) => {

  const [page, set_page] = useState(0);
  const [count, set_count] = useState(0);
  const [data, set_data] = useState([]);
  const [delete_id, set_delete_id] = useState('');
  const [show_delete_dialog, set_show_delete_dialog] = useState(false);
  const [is_deleting, set_is_deleting] = useState(false);

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [page])

  const getData = async () => {

    const skip = page * rowsPerPage;

    // eslint-disable-next-line
    const [res, error] = await BillService.getBills(skip, rowsPerPage);

    if (res) {
      set_data(res.data.data);
      set_count(res.data.total_records);
    }
  }

  const handleCloseDeleteDialog = () => {
    set_show_delete_dialog(false);
    set_delete_id("");
  }

  const handleDelete = async () => {
    set_is_deleting(true);

    // eslint-disable-next-line
    const [res, error] = await BillService.deleteBill(delete_id);

    if(error){
      showSnackbar("Error while deleting bill !", 'danger');
    }else{
      showSnackbar("Bill deleted successfully !", 'success');
      getData();
    }

    handleCloseDeleteDialog();
    set_is_deleting(false);
  }

  return (
    <Wrapper
      showSnackbar={showSnackbar}
    >
      <div className="d-flex justify-content-between">
        <div className="paper-title font-semibold">
          Bills
        </div>

        <Link to='/bills/add' className="btn text-white background-primary">
          Add New
        </Link>
      </div>

      <div className="bg-white shadow-sm rounded-3 table-container overflow-hidden">
        <table className="table site-table align-middle mb-0">
          <thead>
            <tr>
              <th className="font-medium"> Invoice No </th>
              <th className="font-medium"> Invoice Date </th>
              <th className="font-medium"> Due Date </th>
              <th className="font-medium"> Company </th>
              <th className="font-medium"> Policy No </th>
              <th className="font-medium"> Invoice Amount </th>
              <th className="font-medium"> Actions </th>
            </tr>
          </thead>
          <tbody>

            {
              data.map((item) => {
                return (
                  <tr key={item._id}>
                    <td> {item.invoice_no} </td>
                    <td> {moment(item.invoice_date).isValid() ? moment(item.invoice_date).format("DD-MM-YYYY") : item.invoice_date} </td>
                    <td> {moment(item.due_date).isValid() ? moment(item.due_date).format("DD-MM-YYYY") : item.due_date} </td>
                    <td> {item.company?.name} </td>
                    <td> {item.policy?.policy_no} </td>
                    <td> {item.invoice_amount} </td>
                    <td>
                      <Link to={`/bills/edit/${item._id}`}>
                        <Pencil width={24} height={24} />
                      </Link>
                      <TrashCan
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          set_delete_id(item._id);
                          set_show_delete_dialog(true);
                        }}
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <Pagination
        page={page}
        onPageChange={(page_no) => set_page(page_no)}
        total={count}
        rowsPerPage={10}
      />

      <Dialog
        show={show_delete_dialog}
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              Are you sure you want to delete this bill ?
            </div>
            <div class="modal-footer">
              <button
                className="btn btn-sm"
                onClick={handleCloseDeleteDialog}
              >
                Cancel
              </button>
              <button
                className="btn btn-sm background-primary text-white"
                disabled={is_deleting}
                onClick={handleDelete}
              >
                {is_deleting ? "Deleting . . ." : "Yes, Delete"}
              </button>
            </div>
          </div>

        </div>
      </Dialog>
    </Wrapper>
  )
}

export default Bills;