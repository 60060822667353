import { MantasLogoBase64 } from "./MantasLogoBase64";
import { ShieldIconBase64 } from "./ShieldIconBase64";
import { MantasStampBase64 } from "./MantasStampBase64";
import moment from "moment";
import { CLOUD_PROVIDERS } from "../constants";

export const generatePolicyPDF = (policy) => {

  const aws_regions = policy.aws_regions || [];
  const google_regions = policy.google_regions || [];
  const microsoft_regions = policy.microsoft_regions || [];

  const issue_date = moment(policy.issue_date).isValid() ? moment(policy.issue_date).format("DD-MM-YYYY") : policy.issue_date;
  const expiry_date = moment(policy.expiry_date).isValid() ? moment(policy.expiry_date).format("DD-MM-YYYY") : policy.expiry_date;

  let regions_stack = []

  if(aws_regions.length){
    regions_stack.push(
      {
        text : CLOUD_PROVIDERS.AMAZON_AWS,
        bold : true,
        margin : [0, 0, 0, 5]
      },
      {
        text : aws_regions.join(", ")
      }
    )
  }

  if(google_regions.length){
    regions_stack.push(
      {
        text : CLOUD_PROVIDERS.GOOGLE_GCP,
        bold : true,
        margin : [0, 5, 0, 5]
      },
      {
        text : google_regions.join(", ")
      }
    )
  }

  if(microsoft_regions.length){
    regions_stack.push(
      {
        text : CLOUD_PROVIDERS.MICROSOFT_AZURE,
        bold : true,
        margin : [0, 5, 0, 5]
      },
      {
        text : microsoft_regions.join(", ")
      }
    )
  }

  return {
    header : {

      layout : 'noBorders',
      table : {
        widths : ["*", "*"],
        body : [
          [
            {
              width : 60,
              image : MantasLogoBase64,
              margin : [40, 5, 0, 0],
            },
            {
              width : 30,
              image : ShieldIconBase64,
              margin : [30, 5, 0, 0],
              alignment : 'right'
            }
          ]
        ]
      },
    },
    content : [
      {
        layout : 'noBorders',
        table : {
          widths : ['*'],
          body : [
            [
              {
                text : 'Certificate of Insurance',
                alignment : 'center',
                fillColor : '#2A2AFF',
                color : '#ffffff',
                fontSize : 16,
                bold : true,
                margin : 6
              }
            ]
          ]
        },
        margin : [0, 20, 0, 0]
      },
      {
        text : `Date : ${moment().format('DD-MM-YYYY')}`,
        margin : [0, 10, 0, 10]
      },
      {
        text : 'This is to certify that the following insurance policy has been issued to :',
        margin : [0, 0, 0, 2]
      },
      {
        text : `M/s. ${policy.company?.poc_name}`,
        bold : true
      },
      {
        table : {
          dontBreakRows : true,
          widths : [150, '*'],
          body : [
            [
              {
                text : 'Type',
                style : ['table_title']
              },
              {
                text : 'Parametric Cloud Insurance',
                bold : true,
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Policy Holder',
                style : ['table_title']
              },
              {
                stack : [
                  {
                    text : policy.company?.name
                  },
                  {
                    text : `All declared websited of ${policy.company?.name}`,
                    margin : [0, 2, 0, 0]
                  }
                ],
                margin : [2, 4, 2, 4]
              }
            ],
            // [
            //   {
            //     text : 'Insured',
            //     style : ['table_title']
            //   },
            //   {
            //     text : `All declared websited of ${policy.company?.name}`,
            //     margin : [2, 4, 2, 4]
            //   }
            // ],
            [
              {
                text : 'Address',
                style : ['table_title']
              },
              {
                text : policy.address,
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Business Activity',
                style : ['table_title']
              },
              {
                text : policy.business_activity,
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Waiting Period',
                style : ['table_title']
              },
              {
                text : policy.waiting_period,
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Policy Deductible',
                style : ['table_title']
              },
              {
                text : policy.policy_deductible,
                margin : [2, 4, 2, 4]
              }
            ],
            // [
            //   {
            //     text : 'Downtime covered',
            //     style : ['table_title']
            //   },
            //   {
            //     text : `${policy.downtime_period} Hours`,
            //     margin : [2, 4, 2, 4]
            //   }
            // ],
            [
              {
                text : 'Cloud Providers Covered',
                style : ['table_title']
              },
              {
                text : policy.cloud_providers?.join(', '),
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Region Names and Availability Zones',
                style : ['table_title']
              },
              {
                stack : regions_stack,
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Polcy No.',
                style : ['table_title']
              },
              {
                text : policy.policy_no,
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Period of Insurance',
                style : ['table_title']
              },
              {
                text : [
                  {
                    text : 'From : ',
                    bold : true
                  },
                  issue_date,
                  {
                    text : ' To : ',
                    bold : true
                  },
                  expiry_date,
                  {
                    text : ' (Both Days Inclusive)',
                    bold : true
                  }
                ],
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Territorial Scope',
                style : ['table_title']
              },
              {
                text : policy.territorial_scope,
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Law & Jurisdiction',
                style : ['table_title']
              },
              {
                text : policy.law_and_jurisdiction,
                margin : [2, 4, 2, 4]
              }
            ],
            [
              {
                text : 'Conditions',
                style : ['table_title']
              },
              {
                margin : [2, 4, 2, 4],
                ul : [
                  'As per Mantas Paramteric Cloud Outage Insurance Policy Wording',
                  'Cancellation Clause: 30 days from Inception',
                  'Premium Payment Warranty: 30 days',
                  'In case of cancellation, short-period rate shall apply',
                  'Sanctions & Limitations Exclusion Clause - LMA 3100',
                  'Excluding all matters which have been notified to the insured or which the insured is aware at inception'
                ]
              }
            ],
            [
              {
                text : 'Exclusions',
                style : ['table_title']
              },
              {
                margin : [2, 4, 2, 4],
                ul : [
                  'Excluding Professional Indemnity',
                  'Excluding Cyber Liability, Cyber War/ Attacks & Operations',
                  'Excluding Punitive & Exemplary Damages',
                  'Excluding Product Liability, Product Guarantee and Product Recall',
                  'Excluding Sabotage, Terrorism War and Civil War',
                  'Insolvency Exclusion',
                  'Money Laundering Exclusion',
                  'Excluding Worldwide Cloud Service Failure',
                  'Russia, Belarus & Ukraine Exclusion',
                  'Excluding Bodily Injury & Property Damage'
                ]
              }
            ]
          ]
        },
        margin : [0, 20, 0, 20]
      },
      {
        text : "Table of Benefits :",
        bold : true,
        margin : [0, 0, 0, 10]
      },
      {
        table : {
          widths : ['*', '*'],
          body : [
            [
              {
                text : 'Insured Events',
                margin : [2, 2, 2, 2],
                fillColor : '#2A2AFF',
                color : '#ffffff',
                bold : true
              },
              {
                text : 'Sum Insured',
                margin : [2, 2, 2, 2],
                fillColor : '#2A2AFF',
                color : '#ffffff',
                bold : true
              }
            ],
            [
              {
                text : 'Section 1 - Cloud Outage Insurance',
                margin : [2, 2, 2, 2],
                bold : true
              },
              {
                text : '',
                margin : [2, 2, 2, 2],
              }
            ],
            [
              {
                text : 'Cloud Outage',
                margin : [2, 2, 2, 2]
              },
              {
                text : policy.coverage_value,
                margin : [2, 2, 2, 2],
              }
            ],
            [
              {
                text : 'Section 2 - Extensions',
                margin : [2, 2, 2, 2],
                bold : true
              },
              {
                text : '',
                margin : [2, 2, 2, 2],
              }
            ],
            [
              {
                margin : [2, 2, 2, 2],
                ul : [
                  'Extensions to be added for a sub-limit and for an additional premium',
                  'Can be covered as a percentage of the Limit of Indemnity instead of the full limit'
                ]
              },
              {
              }
            ],
          ]
        }
      },
      {
        text : 'This Certificate is issued subject to the terms, conditions, provisions and exclusions of the original policy.',
        margin : [0, 20, 0, 0]
      },
      {
        text : 'Yours sincerely,',
        margin : [0, 20, 0, 0]
      },
      {
        text : 'For: Mantas Insurance Solutions, Inc',
        bold : true
      },
      {
        image : MantasStampBase64,
        width : 120,
        margin : [0, 10, 0, 10]
      },
      {
        text : '(Authorized Signatory)'
      },
    ],
    defaultStyles : {
      fontSize : 10
    },
    styles : {
      table_title : {
        bold : true,
        margin : [2, 4, 2, 4],
        fillColor : '#6D6D6D',
        fillOpacity : 0.21
      }
    }
  }
}