import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';
import Dashboard from "./Pages/Dashboard";
import Companies from "./Pages/Companies";
import AddCompany from "./Pages/AddCompany";
import Agencies from "./Pages/Agencies";
import AddAgency from "./Pages/AddAgency";
import Policies from "./Pages/Policies";
import AddPolicy from "./Pages/AddPolicy";
import Bills from "./Pages/Bills";
import AddBill from "./Pages/AddBill";
import { useEffect, useState } from "react";
import Login from "./Pages/Login";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  
  const [snackbar, set_snackbar] = useState({
    show : false,
    message : '',
    type : ''
  })

  const showSnackbar = (message, type = 'success') => {
    
    set_snackbar({
      show : true,
      message : message,
      type : type
    })
  }

  useEffect(() => {
    if(snackbar.show){
      setTimeout(() => {
        set_snackbar({
          ...snackbar,
          show : false
        })
      }, 3000)
    }
  }, [snackbar])

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element = {<Dashboard showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/accounts"
            element = {<Companies showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/accounts/add"
            element = {<AddCompany showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/accounts/edit/:id"
            element = {<AddCompany showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/agencies"
            element = {<Agencies showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/agencies/add"
            element = {<AddAgency showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/policies"
            element = {<Policies showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/policies/add"
            element = {<AddPolicy showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/policies/edit/:id"
            element = {<AddPolicy showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/bills"
            element = {<Bills showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/bills/add"
            element = {<AddBill showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/bills/edit/:id"
            element = {<AddBill showSnackbar = {showSnackbar} />}
          />
          <Route
            path="/login"
            element = {<Login showSnackbar = {showSnackbar} />}
          />
        </Routes>
      </Router>

      <div className={`text-bg-${snackbar.type} snackbar ${snackbar.show ? 'show' : ''}`}>
        {snackbar.message}
      </div>
    </>
  );
}

export default App;
