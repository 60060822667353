import axios from "axios";
import { api_base_url } from "./config";

const auth = {};

auth.login = async (username, password) => {

  const url = `${api_base_url}/auth/login`

  try {
    const res = await axios({
      url: url,
      method: "POST",
      data: {
        username: username,
        password: password
      }
    });

    return [res, null]
  } catch (error) {
    return [null, error]
  }
}

auth.logout = () => {
  localStorage.removeItem("ms_mvp_token")
}

auth.checkLogin = async () => {
  const url = `${api_base_url}/auth/check-login`

  try {
    const res = await axios({
      url: url,
      method: "GET"
    });

    return [res, null]
  } catch (error) {
    return [null, error]
  }
}

export default auth;