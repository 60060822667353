import { Link } from "react-router-dom";
import CompaniesTableRow from "../Components/CompaniesTableRow";
import Wrapper from "../Components/Wrapper"
import { useEffect, useState } from "react";
import CompanyService from "../services/company";
import Pagination from "../Components/Pagination";
import UbuntuScript from "../shell-scripts/ubuntu.sh";
import DebianScript from "../shell-scripts/debian.sh";
import CentosScript from "../shell-scripts/centos.sh";
import OpenSuseScript from "../shell-scripts/open-suse.sh";
import SuseScript from "../shell-scripts/suse.sh";
import FedoraScript from "../shell-scripts/fedora.sh";
import WindowsScript from "../shell-scripts/windows.sh";
import axios from "axios";
import Dialog from "../Components/Dialog";

const rowsPerPage = 10;

const Companies = ({
  showSnackbar
}) => {

  const [page, set_page] = useState(0);
  const [count, set_count] = useState(0);
  const [data, set_data] = useState([]);
  const [shell_scripts, set_shell_scripts] = useState({});
  const [delete_id, set_delete_id] = useState('');
  const [show_delete_dialog, set_show_delete_dialog] = useState(false);
  const [is_deleting, set_is_deleting] = useState(false);

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [page])

  const getData = async () => {
    
    const skip = page * rowsPerPage;
    
    // eslint-disable-next-line
    const [res, error] = await CompanyService.getCompanies(skip, rowsPerPage);

    if(res){
      set_data(res.data.data);
      set_count(res.data.total_records);
    }
  }

  useEffect(() => {
    getScriptText()
  }, [])

  const getScriptText = async () => {
    const shell_scripts_obj = {
      'Ubuntu' : {
        script : (await axios(UbuntuScript)).data,
        file : UbuntuScript
      },
      'Debian' : {
        script : (await axios(DebianScript)).data,
        file : DebianScript
      },
      'Centos' : {
        script : (await axios(CentosScript)).data,
        file : CentosScript
      },
      'OpenSuSe' : {
        script : (await axios(OpenSuseScript)).data,
        file : OpenSuseScript
      },
      'SuSe' : {
        script : (await axios(SuseScript)).data,
        file : SuseScript
      },
      'Fedora' : {
        script : (await axios(FedoraScript)).data,
        file : FedoraScript
      },
      'Windows' : {
        script : (await axios(WindowsScript)).data,
        file : WindowsScript
      }
    }

    set_shell_scripts(shell_scripts_obj);
  }

  const handleCloseDeleteDialog = () => {
    set_show_delete_dialog(false);
    set_delete_id("");
  }

  const handleDelete = async () => {
    set_is_deleting(true);

    // eslint-disable-next-line
    const [res, error] = await CompanyService.deleteCompany(delete_id);

    if(error){
      showSnackbar("Error while deleting account !", 'danger');
    }else{
      showSnackbar("Account deleted successfully !", 'success');
      getData();
    }

    handleCloseDeleteDialog();
    set_is_deleting(false);
  }

  const handleClickDelete = (id) => {
    set_delete_id(id);
    set_show_delete_dialog(true);
  }
  return(
    <Wrapper
      container_class='company-list-container'
      showSnackbar = {showSnackbar}
    >

      <div className="d-flex justify-content-between">
        <div className="paper-title font-semibold">
          Accounts
        </div>
        
        <Link to='/accounts/add' className="btn text-white background-primary">
          Add New 
        </Link>
      </div>

      <div className="bg-white shadow-sm rounded-3 table-container overflow-hidden">
        <table className="table site-table align-middle mb-0">
          <thead>
            <tr>
              <th className="font-medium"> Name </th>
              <th className="font-medium"> Status </th>
              <th className="font-medium"> URL </th>
              <th className="font-medium"> Policy Type </th>
              <th className="font-medium"> Agent </th>
              <th className="font-medium"> Status Alert </th>
              <th className="font-medium"> Actions </th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((company_item, ind) => {

                const server = company_item.cloud_providers || [];

                return(
                  <CompaniesTableRow
                    key={`COMPANY_DETAIL_${ind}`}
                    title={company_item.name}
                    server={server.join(', ')}
                    status={'Up'}
                    status_message={'in 3/3 regions, Checked 1:03:36 PM'}
                    url={company_item.main_website_url}
                    policy_type={company_item.insurance_line_type}
                    status_alert={company_item.status_alert}
                    id={company_item._id}
                    shell_scripts = {shell_scripts}
                    showSnackbar = {showSnackbar}
                    handleClickDelete = {handleClickDelete}
                  />
                )
              })
            }
          </tbody>
        </table>

      </div>
      <Pagination
        page = {page}
        onPageChange = {(page_no) => set_page(page_no)}
        total = {count}
        rowsPerPage={10}
      />

      <Dialog
        show={show_delete_dialog}
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              Are you sure you want to delete this account ?
            </div>
            <div class="modal-footer">
              <button
                className="btn btn-sm"
                onClick={handleCloseDeleteDialog}
              >
                Cancel
              </button>
              <button
                className="btn btn-sm background-primary text-white"
                disabled={is_deleting}
                onClick={handleDelete}
              >
                {is_deleting ? "Deleting . . ." : "Yes, Delete"}
              </button>
            </div>
          </div>

        </div>
      </Dialog>
    </Wrapper>
  )
}

export default Companies;