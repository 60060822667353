import { useState } from "react";
import Wrapper from "../Components/Wrapper"

const AddAgency = ({
  showSnackbar
}) => {

  const [details, set_details] = useState({
    name : '',
    poc_name : '',
    poc_phone_number : '',
    poc_email_id : '',
    poc_eid_number : '',
    finance_name : '',
    finance_phone_number : '',
    finance_email_id : '',
    finance_eid_number : ''
  })

  const handleChangeDetails = (e) => {
    set_details({
      ...details,
      [e.target.name] : e.target.value
    })
  }

  return(
    <Wrapper
      container_class='add-items-form'
      showSnackbar = {showSnackbar}
    >

      <div className="paper-title font-semibold">
        Add new agency
      </div>
      <div className="input-row bg-white shadow-sm rounded-3">
        <div>
          <label className="form-label"> Name </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "name"
            value={details.name}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> POC Name </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "poc_name"
            value={details.poc_name}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> POC Phone number </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "poc_phone_number"
            value={details.poc_phone_number}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> POC Email ID </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "poc_email_id"
            value={details.poc_email_id}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> POC EID Number </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "poc_eid_number"
            value={details.poc_eid_number}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Finance Name </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "finance_name"
            value={details.finance_name}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Finance Phone Number </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "finance_phone_number"
            value={details.finance_phone_number}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Finance Email ID </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "finance_email_id"
            value={details.finance_email_id}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Finance EID Number </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "finance_eid_number"
            value={details.finance_eid_number}
            onChange={handleChangeDetails}
          />
        </div>

        <div className="d-flex full-width justify-content-end">
          <button className="btn background-primary text-white">
            Save
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default AddAgency;