import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg";

const Pagination = ({
  page,
  total,
  rowsPerPage,
  onPageChange
}) => {

  const page_no = page + 1;

  const last_count = page_no * rowsPerPage;

  const last_count_show = last_count > total ? total : last_count;

  const first_count = total === 0 ? 0 : last_count - rowsPerPage + 1;

  return(
    <div className="pagination shadow-sm rounded-3 d-flex align-items-center justify-content-end">
      <div className="font-medium">
        {first_count} - {last_count_show} of {Number(total).toLocaleString()} results
      </div>

      <button
        className="btn btn-secondary pagination-btn d-flex align-items-center justify-content-center p-0"
        disabled = {page === 0}
        onClick={() => onPageChange(page - 1)}
      >
        <ArrowLeft />
      </button>

      <button
        className="btn btn-secondary pagination-btn d-flex align-items-center justify-content-center p-0"
        disabled = {last_count >= total}
        onClick={() => onPageChange(page - 1)}
      >
        <ArrowRight />
      </button>
    </div>
  )
}

export default Pagination;