export const MICROSOFT_REGIONS = [
  {
    label: "North America:",
    is_region_name : true
  },
  {
    label: "East US - East US"
  },
  {
    label: "East US 2 - East US 2"
  },
  {
    label: "West US - West US"
  },
  {
    label: "West US 2 - West US 2"
  },
  {
    label: "West US 3 - West US 3"
  },
  {
    label: "Central US - Central US"
  },
  {
    label: "North Central US - North Central US"
  },
  {
    label: "South Central US - South Central US"
  },
  {
    label: "West Central US - West Central US"
  },
  {
    label: "Canada East - Canada East"
  },
  {
    label: "Canada Central - Canada Central"
  },
  {
    label: "South America:",
    is_region_name : true
  },
  {
    label: "Brazil South - Brazil South"
  },
  {
    label: "Brazil Southeast - Brazil Southeast"
  },
  {
    label: "Europe:",
    is_region_name : true
  },
  {
    label: "North Europe - North Europe (Ireland)"
  },
  {
    label: "West Europe - West Europe (Netherlands)"
  },
  {
    label: "France Central - France Central"
  },
  {
    label: "France South - France South"
  },
  {
    label: "Germany West Central - Germany West Central"
  },
  {
    label: "Germany North - Germany North"
  },
  {
    label: "UK South - UK South"
  },
  {
    label: "UK West - UK West"
  },
  {
    label: "Norway East - Norway East"
  },
  {
    label: "Norway West - Norway West"
  },
  {
    label: "Switzerland North - Switzerland North"
  },
  {
    label: "Switzerland West - Switzerland West"
  },
  {
    label: "Sweden Central - Sweden Central"
  },
  {
    label: "Austria East - Austria East"
  },
  {
    label: "Middle East and Africa:",
    is_region_name : true
  },
  {
    label: "UAE North - UAE North"
  },
  {
    label: "UAE Central - UAE Central"
  },
  {
    label: "South Africa North - South Africa North"
  },
  {
    label: "South Africa West - South Africa West"
  },
  {
    label: "Qatar Central - Qatar Central"
  },
  {
    label: "Israel Central - Israel Central"
  },
  {
    label: "Asia Pacific:",
    is_region_name : true
  },
  {
    label: "East Asia - East Asia (Hong Kong)"
  },
  {
    label: "Southeast Asia - Southeast Asia (Singapore)"
  },
  {
    label: "Australia East - Australia East"
  },
  {
    label: "Australia Southeast - Australia Southeast"
  },
  {
    label: "Japan East - Japan East"
  },
  {
    label: "Japan West - Japan West"
  },
  {
    label: "Korea Central - Korea Central"
  },
  {
    label: "Korea South - Korea South"
  },
  {
    label: "India Central - India Central"
  },
  {
    label: "India South - India South"
  }
]