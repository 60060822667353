import Switch from "./Switch";
import { ReactComponent as Ubuntu } from "../assets/icons/ubuntu-logo.svg";
import { ReactComponent as Debian } from "../assets/icons/debian-logo.svg";
import { ReactComponent as Centos } from "../assets/icons/centos-logo.svg";
import { ReactComponent as OpenSuSe } from "../assets/icons/open-suse-logo.svg";
import { ReactComponent as SuSe } from "../assets/icons/suse-logo.svg";
import { ReactComponent as Fedora } from "../assets/icons/fedora-logo.svg";
import { ReactComponent as Windows } from "../assets/icons/windows-logo.svg";
import { useEffect, useState } from "react";
import { ReactComponent as Download } from "../assets/icons/download.svg";
import { ReactComponent as Copy } from "../assets/icons/copy.svg";
import { ReactComponent as Caret } from "../assets/icons/caret-down.svg";
import { ReactComponent as Pencil } from "../assets/icons/pencil.svg";
import { Link } from "react-router-dom";
import { saveAs } from 'file-saver';
import { ReactComponent as TrashCan } from "../assets/icons/trash-can.svg";

const AGENTS = [
  {
    title: "Ubuntu",
    icon: <Ubuntu className="icon" />
  },
  {
    title: "Debian",
    icon: <Debian className="icon" />
  },
  {
    title: "Centos",
    icon: <Centos className="icon" />
  },
  {
    title: "OpenSuSe",
    icon: <OpenSuSe className="icon" />
  },
  {
    title: "SuSe",
    icon: <SuSe className="icon" />
  },
  {
    title: "Fedora",
    icon: <Fedora className="icon" />
  },
  {
    title: "Windows",
    icon: <Windows className="icon" />
  }
]

const CompaniesTableRow = ({
  title,
  server,
  status,
  status_message,
  url,
  policy_type,
  status_alert,
  id,
  shell_scripts,
  showSnackbar,
  handleClickDelete
}) => {

  const [show_details_row, set_show_details_row] = useState(false);
  const [selected_agent, set_selected_agent] = useState('');
  const [code_box_open, set_code_box_open] = useState(false);

  useEffect(() => {
    if (!show_details_row) {
      set_selected_agent('')
    }
  }, [show_details_row])

  const downloadBashFile = () => {
    saveAs(shell_scripts[selected_agent].file, 'createHost.sh')
  }

  const copyScript = () => {
    const script_to_copy = shell_scripts[selected_agent].script;
    navigator.clipboard.writeText(script_to_copy);
    showSnackbar('Script Copied !', 'success');
  }

  return (
    <>
      <tr>
        <td>
          <div>
            {title}
          </div>
          <div className="text-secondary mt-1">
            {server}
          </div>
        </td>

        <td>
          <div>
            {
              status === 'Up' ?
                <span className="badge rounded-pill font-regular text-bg-success">Up</span> :
                <span className="badge rounded-pill font-regular text-bg-danger">Down</span>
            }
          </div>
          <div className="text-secondary mt-1">
            {status_message}
          </div>
        </td>

        <td>
          <span className="text-dark text-underline"> {url} </span>
        </td>

        <td>
          {policy_type}
        </td>

        <td>
          <button
            className="btn btn-sm background-primary text-white"
            onClick={() => set_show_details_row(!show_details_row)}
          >
            Manage
          </button>
        </td>

        <td>
          <Switch checked={status_alert} />
        </td>

        <td>
          <Link to={`/accounts/edit/${id}`}>
            <Pencil width={24} height={24} />
          </Link>

          <TrashCan
            className="ms-2 cursor-pointer"
            onClick={() => handleClickDelete(id)}
          />
        </td>
      </tr>

      {
        show_details_row &&
        <tr>
          <td colSpan={6} className="p-0">
            <div className="company-details-row">
              <div className="paper-title font-semibold">
                Select an agent
              </div>

              <div className="top-description font-regular">
                Monitor company websites infrastructure. You can use one of the provided installation scripts for a smooth integration. Choose client’s operating system:
              </div>

              <div className="agents-row">

                {
                  AGENTS.map((agent, ind) => {

                    const agent_title = agent.title;

                    return (
                      <div
                        key={`AGENT-${ind}`}
                        className={`d-flex flex-column align-items-center agent-box shadow-sm rounded-3 ${selected_agent === agent_title ? 'active' : ''}`}
                        onClick={() => set_selected_agent(agent_title)}
                      >
                        {agent.icon}

                        <div className="title">
                          {agent_title}
                        </div>
                      </div>
                    )
                  })
                }
              </div>

              {
                Boolean(selected_agent) &&
                <div className="agent-details-row">
                  <div className="steps-container">
                    <div className="d-flex step">
                      <div className="number-container d-flex align-items-center justify-content-center flex-shrink-0">
                        1
                      </div>

                      <div>
                        <div className="title font-semibold">
                          Download :
                        </div>
                        <div className="description">
                          Save the script to your host, that you want to monitor.
                        </div>
                      </div>
                    </div>

                    <div className="d-flex step">
                      <div className="number-container d-flex align-items-center justify-content-center flex-shrink-0">
                        2
                      </div>

                      <div>
                        <div className="title font-semibold">
                          Execute :
                        </div>
                        <div className="description">
                          Run the saved script using this command :

                          <div className="source-code-pro">
                            chmod +x createHost.sh; ./createHost.sh
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex step">
                      <div className="number-container d-flex align-items-center justify-content-center flex-shrink-0">
                        3
                      </div>

                      <div>
                        <div className="title font-semibold">
                          Monitor services :
                        </div>
                        <div className="description">
                          You can view the tracked infrastructure under client monitoring dashboard
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="code-container">
                    <div className="title">

                      <div className="d-inline-flex align-items-center cursor-pointer" onClick={downloadBashFile}>
                        <Download className="download-icon" />
                        createHost.sh
                      </div>

                    </div>

                    <div className={`code-box source-code-pro position-relative ${code_box_open ? 'open' : ''}`}>

                      <div
                        className="copy-box d-flex align-items-center justify-content-center cursor-pointer"
                        onClick={copyScript}
                      >
                        <Copy />
                      </div>
                      <div>
                        <pre>
                          {
                            shell_scripts[selected_agent]?.script
                          }
                        </pre>
                      </div>
                    </div>

                    <div
                      className={`open-row d-flex align-items-center justify-content-center ${code_box_open ? 'open' : ''}`}
                      onClick={() => set_code_box_open(!code_box_open)}
                    >
                      <Caret className="caret-icon" />
                    </div>
                  </div>
                </div>
              }
            </div>
          </td>
        </tr>
      }
    </>
  )
}

export default CompaniesTableRow;