import { Link } from "react-router-dom"
import Wrapper from "../Components/Wrapper"

const Agencies = ({
  showSnackbar
}) => {
  return(
    <Wrapper
      showSnackbar = {showSnackbar}
    >
      <div className="d-flex justify-content-between">
        <div className="paper-title font-semibold">
          Agencies
        </div>
        
        <Link to='/agencies/add' className="btn text-white background-primary">
          Add New 
        </Link>
      </div>
    </Wrapper>
  )
}

export default Agencies;