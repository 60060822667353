import { forwardRef } from "react";
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";

const CustomDateInput = forwardRef(
  ({ value, onClick, placeholder }, ref) => (
    <div
      onClick={onClick}
      ref={ref}
      className="d-flex align-items-center justify-content-between custom-date-input cursor-pointer"
    >
      <div>
        {value || placeholder}
      </div>
      <Calendar className="icon" />
    </div>
  ),
);

export default CustomDateInput;