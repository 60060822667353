import { useEffect, useState } from "react";
import Wrapper from "../Components/Wrapper";
import { ReactComponent as TrashCan } from "../assets/icons/trash-can.svg";
import { useParams, useNavigate } from "react-router-dom";
import CompanyService from "../services/company";

const CLOUD_PROVIDERS = ["AWS", "GCP", "Azure", "G42", "Other"];

const blank_details_obj = {
  name: '',
  poc_name: '',
  poc_phone_number: '',
  poc_email_id: '',
  poc_eid_number: '',
  finance_name: '',
  finance_phone_number: '',
  finance_email_id: '',
  finance_eid_number: '',
  trn_number: '',
  main_website_url: '',
  agency_id: '',
  cloud_providers: [],
  insurance_line_type: '',
  websites: []
}

const AddCompany = ({
  showSnackbar
}) => {

  const [details, set_details] = useState(blank_details_obj);

  const [website_name, set_website_name] = useState('');
  const [website_url, set_website_url] = useState('');
  const [is_edit, set_is_edit] = useState(false);
  const [is_saving, set_is_saving] = useState(false);

  const navigate = useNavigate();

  const handleChangeDetails = (e) => {
    set_details({
      ...details,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeCloudProvider = (value) => {
    let cloud_providers = details.cloud_providers ? [...details.cloud_providers] : [];

    const value_index = cloud_providers.findIndex(provider => provider === value);

    if (value_index < 0) {
      cloud_providers.push(value)
    } else {
      cloud_providers.splice(value_index, 1)
    }

    set_details({
      ...details,
      cloud_providers: cloud_providers
    })
  }

  const addWebsite = () => {
    let websites = details.websites ? [...details.websites] : [];

    websites.push({
      title: website_name,
      url: website_url
    });

    setWebsites(websites);
    set_website_name('');
    set_website_url('');
  }

  const deleteWebsite = (ind) => {
    let websites = details.websites ? [...details.websites] : [];
    websites.splice(ind, 1);
    setWebsites(websites);
  }

  const setWebsites = (websites) => {
    set_details({
      ...details,
      websites: websites
    })
  }

  const params = useParams();
  const company_id = params.id;

  const getCompany = async () => {
    // eslint-disable-next-line
    const [res, error] = await CompanyService.getCompany(company_id);

    if(res){

      const data_obj = res.data.data;

      const details_obj = {
        name: data_obj.name,
        poc_name: data_obj.poc_name,
        poc_phone_number: data_obj.poc_phone_number,
        poc_email_id: data_obj.poc_email_id,
        poc_eid_number: data_obj.poc_eid_number,
        finance_name: data_obj.finance_name,
        finance_phone_number: data_obj.finance_phone_number,
        finance_email_id: data_obj.finance_email_id,
        finance_eid_number: data_obj.finance_eid_number,
        trn_number: data_obj.trn_number,
        main_website_url: data_obj.main_website_url,
        agency_id: data_obj.agency_id,
        cloud_providers: data_obj.cloud_providers,
        insurance_line_type: data_obj.insurance_line_type,
        websites: data_obj.websites
      }
      
      set_details(details_obj);
    }
  }

  useEffect(() => {
    if(company_id){
      set_is_edit(true);

      getCompany();
    }
    // eslint-disable-next-line
  }, [company_id]);

  const navigateToAccountsPage = () => {
    navigate('/accounts');
  }

  const handleClickSave = async () => {
    set_is_saving(true);
    if(is_edit){
      const _details = {...details};
      delete _details._id;

      // eslint-disable-next-line
      const [res, error] = await CompanyService.updateCompany(company_id, _details);
      
      if(error){
        showSnackbar('Error while saving company !', 'danger')
      }else{
        showSnackbar('Company details updated successfully !', 'success')
      }
      set_is_saving(false);
      setTimeout(() => {
        navigateToAccountsPage();
      }, 1000)
    }else{
      // eslint-disable-next-line
      const [res, error] = await CompanyService.addCompany(details);
      
      if(error){
        showSnackbar('Error while saving company !', 'danger')
      }else{
        showSnackbar('Company account created successfully !', 'success');
        set_details(blank_details_obj);
      }
      set_is_saving(false);
      setTimeout(() => {
        navigateToAccountsPage();
      }, 1000)
    }
  }

  return (
    <Wrapper
      container_class='add-items-form'
      showSnackbar = {showSnackbar}
    >

      <div className="paper-title font-semibold">
        {
          is_edit ? 'Edit account' : 'Add new account'
        }
      </div>
      <div className="input-row bg-white shadow-sm rounded-3">
        <div>
          <label className="form-label required"> Name </label>
          <input
            type="text"
            className="form-control font-medium"
            name="name"
            value={details.name}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> POC Name </label>
          <input
            type="text"
            className="form-control font-medium"
            name="poc_name"
            value={details.poc_name}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> POC Phone number </label>
          <input
            type="text"
            className="form-control font-medium"
            name="poc_phone_number"
            value={details.poc_phone_number}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> POC Email ID </label>
          <input
            type="text"
            className="form-control font-medium"
            name="poc_email_id"
            value={details.poc_email_id}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> POC EID Number </label>
          <input
            type="text"
            className="form-control font-medium"
            name="poc_eid_number"
            value={details.poc_eid_number}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Finance Name </label>
          <input
            type="text"
            className="form-control font-medium"
            name="finance_name"
            value={details.finance_name}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Finance Phone Number </label>
          <input
            type="text"
            className="form-control font-medium"
            name="finance_phone_number"
            value={details.finance_phone_number}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Finance Email ID </label>
          <input
            type="text"
            className="form-control font-medium"
            name="finance_email_id"
            value={details.finance_email_id}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Finance EID Number </label>
          <input
            type="text"
            className="form-control font-medium"
            name="finance_eid_number"
            value={details.finance_eid_number}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> TRN number </label>
          <input
            type="text"
            className="form-control font-medium"
            name="trn_number"
            value={details.trn_number}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Main Website's URL </label>
          <input
            type="text"
            className="form-control font-medium"
            name="main_website_url"
            value={details.main_website_url}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Agency </label>
          <select
            className="form-select font-medium"
            name="agency_id"
            value={details.agency_id}
            onChange={handleChangeDetails}
          >
            <option value=''> Select Agency </option>
            <option value='agency_1'> Agency 1 </option>
            <option value='agency_2'> Agency 2 </option>
            <option value='agency_3'> Agency 3 </option>
          </select>
        </div>

        <div>
          <label className="form-label required"> Cloud Provider </label>

          <div>

            {
              CLOUD_PROVIDERS.map((cloud_provider, ind) => {
                return (
                  <div className="form-check form-check-inline" key={`CLOUD-PROVIDER-${ind}`}>
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={cloud_provider}
                        onChange={() => handleChangeCloudProvider(cloud_provider)}
                        checked={details.cloud_providers.includes(cloud_provider)}
                      />

                      {cloud_provider}
                    </label>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div>
          <label className="form-label required"> Insurance Line Type </label>

          <div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  name="insurance_line_type"
                  className="form-check-input"
                  onChange={handleChangeDetails}
                  value='Direct'
                  checked = {details.insurance_line_type === 'Direct'}
                />

                Direct
              </label>
            </div>

            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  name="insurance_line_type"
                  className="form-check-input"
                  onChange={handleChangeDetails}
                  value='Indirect'
                  checked = {details.insurance_line_type === 'Indirect'}
                />

                Indirect
              </label>
            </div>
          </div>
        </div>

        <div></div>

        <div>
          <label className="form-label required"> Websites </label>

          <div className="websites-form-container border rounded-3 d-flex flex-column">
            <div>
              <label className="form-label required"> Website title </label>
              <input
                type="text"
                className="form-control font-medium"
                name="website_name"
                value={website_name}
                onChange={(e) => set_website_name(e.target.value)}
              />
            </div>

            <div>
              <label className="form-label required"> Website URL </label>
              <input
                type="text"
                className="form-control font-medium"
                name="website_url"
                value={website_url}
                onChange={(e) => set_website_url(e.target.value)}
              />
            </div>

            <div className="d-flex justify-content-end">
              <button
                className="btn btn-outline-info"
                onClick={addWebsite}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div>
          <label className="form-label"> Websites List </label>
          <div className="websites-container border rounded-3 d-flex flex-column">

            {
              (details.websites && details.websites.length > 0) ?
                details.websites.map((website, ind) => {
                  return (
                    <div className="d-flex align-items-center website-item border rounded-3" key={`WEBSITE-${ind}`}>
                      <div className="flex-grow-1">
                        <div className="font-semibold">
                          {website.title}
                        </div>
                        <div>
                          {website.url}
                        </div>
                      </div>

                      <button className="btn btn-sm btn-danger icon-btn" onClick={() => deleteWebsite(ind)}>
                        <TrashCan className="icon" />
                      </button>
                    </div>
                  )
                }) :

                <div className="text-center font-semibold">
                  No website added
                </div>
            }

          </div>
        </div>

        <div className="d-flex full-width justify-content-end">
          <button
            className="btn background-primary text-white"
            onClick={handleClickSave}
            disabled = {is_saving}
          >
            {is_saving ? "Saving . . ." : "Save"}
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default AddCompany;