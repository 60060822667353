export const AMAZON_REGIONS = [
  {
    label: "North America:",
    is_region_name : true
  },
  {
    label: "US East (N. Virginia) - us-east-1"
  },
  {
    label: "US East (Ohio) - us-east-2"
  },
  {
    label: "US West (N. California) - us-west-1"
  },
  {
    label: "US West (Oregon) - us-west-2"
  },
  {
    label: "Canada (Central) - ca-central-1"
  },
  {
    label: "South America:",
    is_region_name : true
  },
  {
    label: "South America (São Paulo) - sa-east-1"
  },
  {
    label: "Europe:",
    is_region_name : true
  },
  {
    label: "EU (Ireland) - eu-west-1"
  },
  {
    label: "EU (London) - eu-west-2"
  },
  {
    label: "EU (Paris) - eu-west-3"
  },
  {
    label: "EU (Stockholm) - eu-north-1"
  },
  {
    label: "EU (Frankfurt) - eu-central-1"
  },
  {
    label: "EU (Milan) - eu-south-1"
  },
  {
    label: "EU (Spain) - eu-south-2"
  },
  {
    label: "Middle East:",
    is_region_name : true
  },
  {
    label: "Middle East (Bahrain) - me-south-1"
  },
  {
    label: "Middle East (UAE) - me-central-1"
  },
  {
    label: "Israel (Tel Aviv) - il-central-1"
  },
  {
    label: "Africa:",
    is_region_name : true
  },
  {
    label: "Africa (Cape Town) - af-south-1"
  },
  {
    label: "Asia Pacific:",
    is_region_name : true
  },
  {
    label: "Asia Pacific (Hong Kong) - ap-east-1"
  },
  {
    label: "Asia Pacific (Tokyo) - ap-northeast-1"
  },
  {
    label: "Asia Pacific (Osaka) - ap-northeast-3"
  },
  {
    label: "Asia Pacific (Seoul) - ap-northeast-2"
  },
  {
    label: "Asia Pacific (Singapore) - ap-southeast-1"
  },
  {
    label: "Asia Pacific (Sydney) - ap-southeast-2"
  },
  {
    label: "Asia Pacific (Jakarta) - ap-southeast-3"
  },
  {
    label: "Asia Pacific (Mumbai) - ap-south-1"
  },
  {
    label: "Asia Pacific (Hyderabad) - ap-south-2"
  },
  {
    label: "China:",
    is_region_name : true
  },
  {
    label: "China (Beijing) - cn-north-1 (Operated by Sinnet)"
  },
  {
    label: "China (Ningxia) - cn-northwest-1 (Operated by NWCD)"
  }
]