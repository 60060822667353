import { Link } from "react-router-dom"
import Wrapper from "../Components/Wrapper"
import PoliciesService from "../services/policies";
import Pagination from "../Components/Pagination";
import { useEffect, useState } from "react";
import { ReactComponent as Pencil } from "../assets/icons/pencil.svg";
import pdfMake from "pdfmake";
import { generatePolicyPDF } from "../helpers/generatePolicyPDF";
import moment from "moment";
import { ReactComponent as TrashCan } from "../assets/icons/trash-can.svg";
import Dialog from "../Components/Dialog";

const rowsPerPage = 10;

const Policies = ({
  showSnackbar
}) => {

  const [page, set_page] = useState(0);
  const [count, set_count] = useState(0);
  const [data, set_data] = useState([]);
  const [delete_id, set_delete_id] = useState('');
  const [show_delete_dialog, set_show_delete_dialog] = useState(false);
  const [is_deleting, set_is_deleting] = useState(false);

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [page])

  const getData = async () => {

    const skip = page * rowsPerPage;

    // eslint-disable-next-line
    const [res, error] = await PoliciesService.getPolicies(skip, rowsPerPage);

    if (res) {
      set_data(res.data.data);
      set_count(res.data.total_records);
    }
  }

  const downloadPolicyPDF = (policy) => {

    const docDefinition = generatePolicyPDF(policy);

    pdfMake.fonts = {
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
    }

    const customTableLayouts = {}

    pdfMake.createPdf(docDefinition, customTableLayouts, pdfMake.fonts).download(`${policy.policy_no}-policy-schedule`);
    // pdfMake.createPdf(docDefinition, customTableLayouts, pdfMake.fonts).open();
  }

  const handleCloseDeleteDialog = () => {
    set_show_delete_dialog(false);
    set_delete_id("");
  }

  const handleDelete = async () => {
    set_is_deleting(true);

    // eslint-disable-next-line
    const [res, error] = await PoliciesService.deletePolicy(delete_id);

    if(error){
      showSnackbar("Error while deleting policy !", 'danger');
    }else{
      showSnackbar("Policy deleted successfully !", 'success');
      getData();
    }

    handleCloseDeleteDialog();
    set_is_deleting(false);
  }

  return (
    <Wrapper
      showSnackbar = {showSnackbar}
    >
      <div className="d-flex justify-content-between">
        <div className="paper-title font-semibold">
          Policies
        </div>

        <Link to='/policies/add' className="btn text-white background-primary">
          Add New
        </Link>
      </div>

      <div className="bg-white shadow-sm rounded-3 table-container overflow-hidden">
        <table className="table site-table align-middle mb-0">
          <thead>
            <tr>
              <th className="font-medium"> Policy No </th>
              <th className="font-medium"> Company </th>
              <th className="font-medium"> Issue Date </th>
              <th className="font-medium"> Expiry Date </th>
              <th className="font-medium"> Coverage Value </th>
              <th className="font-medium"> Authorized By </th>
              <th className="font-medium"> Download Schedule </th>
              <th className="font-medium"> Actions </th>
            </tr>
          </thead>

          <tbody>
            {
              data.map((policy) => {

                return (
                  <tr key={policy._id}>
                    <td> {policy.policy_no} </td>
                    <td> {policy.company?.name} </td>
                    <td> {moment(policy.issue_date).isValid() ? moment(policy.issue_date).format("DD-MM-YYYY") : policy.issue_date} </td>
                    <td> {moment(policy.expiry_date).isValid() ? moment(policy.expiry_date).format("DD-MM-YYYY") : policy.expiry_date} </td>
                    <td> {policy.coverage_value} </td>
                    <td> {policy.authorized_by} </td>
                    <td>
                      <button
                        className="btn btn-sm background-primary text-white"
                        onClick={() => downloadPolicyPDF(policy)}
                      >
                        Download
                      </button>
                    </td>
                    <td>
                      <Link to={`/policies/edit/${policy._id}`}>
                        <Pencil width={24} height={24} />
                      </Link>
                      <TrashCan
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          set_show_delete_dialog(true);
                          set_delete_id(policy._id)
                        }}
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <Pagination
        page={page}
        onPageChange={(page_no) => set_page(page_no)}
        total={count}
        rowsPerPage={10}
      />

      <Dialog
        show={show_delete_dialog}
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              Are you sure you want to delete this policy ?
            </div>
            <div class="modal-footer">
              <button
                className="btn btn-sm"
                onClick={handleCloseDeleteDialog}
              >
                Cancel
              </button>
              <button
                className="btn btn-sm background-primary text-white"
                disabled = {is_deleting}
                onClick={handleDelete}
              >
                {is_deleting ? "Deleting . . ." : "Yes, Delete"}
              </button>
            </div>
          </div>

        </div>
      </Dialog>
    </Wrapper>
  )
}

export default Policies;