import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { ReactComponent as Clock } from '../assets/icons/clock.svg';
import UserImage from '../assets/images/user-image.png';
import { useState } from 'react';
import { ReactComponent as CaretDown } from '../assets/icons/caret-down.svg';
import AuthService from "../services/auth";
import { useNavigate } from 'react-router-dom';

const Header = ({
  show_header_search
}) => {

  const [search, set_search] = useState('Last 3 days');
  const [show_dropdown, set_show_dropdown] = useState(false);

  const navigate = useNavigate();

  const handleClickLogout = (e) => {
    e.stopPropagation();
    AuthService.logout();
    navigate('/login');
  }
  return (
    <header className="d-flex align-items-center justify-content-between fixed-top">

      <div>
        <Logo />
      </div>

      {
        show_header_search &&
        <div className="input-group">
          <div className="input-group-text background-primary d-flex align-items-center justify-content-center" style={{ width : '45px' }}>
            <Clock />
          </div>
          <input
            type="text"
            className="form-control no-box-shadow-input font-semibold"
            value={search}
            onChange={(e) => set_search(e.target.value)}
          />
          <div className="input-group-text btn txt-primary">
            Show Dates
          </div>
        </div>
      }

      <div
        className="d-flex align-items-center user-actions"
        onClick={() => set_show_dropdown(!show_dropdown)}
      >
        <img
          src={UserImage}
          alt=''
        />

        <div className='font-semibold'>
          Basil Test
        </div>

        <CaretDown />

        <div className={`dropdown-container bg-white shadow rounded-2 ${show_dropdown ? 'show' : ''}`}>
          <div
            className='item'
            onClick={handleClickLogout}
          >
            Logout
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;