import moment from "moment";
import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left-filled.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right-filled.svg";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CustomDateHeader = ({
  date,
  changeMonth,
  decreaseMonth,
  increaseMonth,
}) => {
  return (
    <div className="custom-datepicker-header d-flex align-items-center justify-content-between">

      <div className="d-flex align-items-center">
        <select
          value={months[moment(date).get("month")]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        {moment(date).get("year")}
      </div>

      <div className="d-flex arrow-container flex-shrink-0">
        <ArrowLeft
          onClick={decreaseMonth}
        />
        <ArrowRight
          onClick={increaseMonth}
        />
      </div>
    </div>
  )
}

export default CustomDateHeader;