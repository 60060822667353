import axios from "axios";
import { api_base_url } from "./config";

const company = {};

company.addCompany = async (data) => {
  const url = `${api_base_url}/companies/add`;

  try{
    const res = await axios({
      url : url,
      method : "POST",
      data : data
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

company.getCompanies = async (skip, limit) => {
  const url = `${api_base_url}/companies/list`

  try{
    const res = await axios({
      url : url,
      params : {
        skip : skip,
        limit : limit
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

company.getCompany = async (id) => {
  const url = `${api_base_url}/companies/get/${id}`

  try{
    const res = await axios({
      url : url
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

company.updateCompany = async (id, data) => {
  const url = `${api_base_url}/companies/update/${id}`

  try{
    const res = await axios({
      url : url,
      method : 'PUT',
      data : {
        data : data
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

company.deleteCompany = async (id, data) => {
  const url = `${api_base_url}/companies/delete/${id}`

  try{
    const res = await axios({
      url : url,
      method : 'DELETE'
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

export default company;