import { useState } from "react";
import AuthService from "../services/auth";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MantasLogo } from "../assets/images/logo.svg"

// 66ba5a9b0af4fd54dcfd0e1d
const Login = ({ showSnackbar }) => {

  const [username, set_username] = useState("");
  const [password, set_password] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const [res] = await AuthService.login(username, password);

    if (res && res.data && res.data.success) {
      localStorage.setItem("ms_mvp_token", res.data.data.token);
      navigate("/");
    } else {
      showErrorMessage()
    }
  }

  const showErrorMessage = () => {
    showSnackbar("Invalid username or password", 'danger')
  }

  return (
    <div className="login-container min-vh-100 d-flex align-items-center justify-content-center">
      <div className="login-form-container d-flex align-items-center">
        <form
          className="login-form"
          onSubmit={handleSubmit}
        >
          <div className="logo-container">
            <MantasLogo />
          </div>

          <div>
            <label className="form-label required"> Username </label>
            <input
              type="text"
              className="form-control font-medium"
              value={username}
              onChange={(e) => set_username(e.target.value)}
            />
          </div>

          <div className="password-container">
            <label className="form-label required"> Password </label>
            <input
              type="password"
              className="form-control font-medium"
              value={password}
              onChange={(e) => set_password(e.target.value)}
            />
          </div>

          <button
            type="submit"
            className="btn background-primary text-white w-100 login-btn"
            disabled={
              username === "" ||
              password === ""
            }
          >
            Login
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login;