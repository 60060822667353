import { useEffect, useState } from "react";
import Wrapper from "../Components/Wrapper"
import CompanyService from "../services/company";
import PoliciesService from "../services/policies";
import { useParams, useNavigate } from "react-router-dom";
import { AMAZON_REGIONS } from "../constants/Amazon-regions";
import { GOOGLE_REGIONS } from "../constants/Google-regions";
import { MICROSOFT_REGIONS } from "../constants/Microsoft-regions";
import { CLOUD_PROVIDERS } from "../constants";
import DatePicker from "react-datepicker";
import CustomDateInput from "../Components/CustomDateInput";
import moment from "moment";
import CustomDateHeader from "../Components/CustomDateHeader";

const CLOUD_PROVIDERS_ARR = [CLOUD_PROVIDERS.AMAZON_AWS, CLOUD_PROVIDERS.GOOGLE_GCP, CLOUD_PROVIDERS.MICROSOFT_AZURE];

const blank_details_obj = {
  policy_no: '',
  company_id: '',
  issue_date: '',
  expiry_date: '',
  authorized_by: '',
  coverage_value: '',
  downtime_period: '',
  address: '',
  business_activity: '',
  waiting_period: '',
  policy_deductible: '',
  territorial_scope: '',
  law_and_jurisdiction: '',
  period_of_insurance: '',
  cloud_providers: [],
  aws_regions: [],
  google_regions: [],
  microsoft_regions: []
}

const AddPolicy = ({
  showSnackbar
}) => {

  const [details, set_details] = useState(blank_details_obj);
  const [companies, set_companies] = useState([]);
  const [is_saving, set_is_saving] = useState(false);
  const [is_edit, set_is_edit] = useState(false);

  const navigate = useNavigate();

  const handleChangeDetails = (e) => {
    set_details({
      ...details,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    getCompanies();

    // eslint-disable-next-line
  }, [])

  const params = useParams();
  const policy_id = params.id;

  useEffect(() => {
    if (policy_id) {
      set_is_edit(true);

      getPolicy();
    }
    // eslint-disable-next-line
  }, [policy_id]);

  const navigateToPoliciesPage = () => {
    navigate('/policies');
  }

  const getPolicy = async () => {
    // eslint-disable-next-line
    const [res, error] = await PoliciesService.getPolicy(policy_id);

    if (res) {

      const data_obj = res.data.data;

      const details_obj = {
        policy_no: data_obj.policy_no,
        company_id: data_obj.company_id,
        issue_date: data_obj.issue_date,
        expiry_date: data_obj.expiry_date,
        authorized_by: data_obj.authorized_by,
        coverage_value: data_obj.coverage_value,
        downtime_period: data_obj.downtime_period,
        address: data_obj.address,
        business_activity: data_obj.business_activity,
        waiting_period: data_obj.waiting_period,
        policy_deductible: data_obj.policy_deductible,
        territorial_scope: data_obj.territorial_scope,
        law_and_jurisdiction: data_obj.law_and_jurisdiction,
        period_of_insurance: data_obj.period_of_insurance,
        cloud_providers: data_obj.cloud_providers || [],
        aws_regions: data_obj.aws_regions || [],
        google_regions: data_obj.google_regions || [],
        microsoft_regions: data_obj.microsoft_regions || []
      }

      set_details(details_obj);
    }
  }

  const getCompanies = async () => {

    // eslint-disable-next-line
    const [res, error] = await CompanyService.getCompanies(null, null);
    if (res) {
      set_companies(res.data.data)
    }
  }

  const handleClickSave = async () => {
    set_is_saving(true);
    if (is_edit) {
      const _details = { ...details };
      delete _details._id;

      // eslint-disable-next-line
      const [res, error] = await PoliciesService.updatePolicy(policy_id, _details);

      if (error) {
        showSnackbar('Error while saving policy !', 'danger')
      } else {
        showSnackbar('Policy details saved successfully !', 'success')
      }
      set_is_saving(false);
      setTimeout(() => {
        navigateToPoliciesPage()
      }, 1000)
    } else {
      // eslint-disable-next-line
      const [res, error] = await PoliciesService.addPolicy(details);

      if (error) {
        showSnackbar('Error while generating policy !', 'danger');
      } else {
        showSnackbar('Policy generated successfully !', 'success');
        set_details(blank_details_obj);
      }
      set_is_saving(false)
      setTimeout(() => {
        navigateToPoliciesPage()
      }, 1000)
    }
  }

  const handleChangeCloudProvider = (value) => {
    let cloud_providers = details.cloud_providers ? [...details.cloud_providers] : [];

    const value_index = cloud_providers.findIndex(provider => provider === value);

    let cloud_provider_regions_keys = {
      [CLOUD_PROVIDERS.AMAZON_AWS]: "aws_regions",
      [CLOUD_PROVIDERS.GOOGLE_GCP]: "google_regions",
      [CLOUD_PROVIDERS.MICROSOFT_AZURE]: "microsoft_regions"
    };

    if (value_index < 0) {
      cloud_providers.push(value)
    } else {
      cloud_providers.splice(value_index, 1)
    }

    set_details({
      ...details,
      cloud_providers: cloud_providers,
      [cloud_provider_regions_keys[value]]: []
    })
  }

  const handleChangeCloudProviderRegion = (region_key, value) => {
    let selected_regions = details[region_key] ? [...details[region_key]] : [];

    const value_index = selected_regions.findIndex(provider => provider === value);

    if (value_index < 0) {
      selected_regions.push(value)
    } else {
      selected_regions.splice(value_index, 1)
    }

    set_details({
      ...details,
      [region_key]: selected_regions
    })
  }

  const handleDateChange = (key, value) => {
    
    set_details({
      ...details,
      [key] : value
    })
  }

  return (
    <Wrapper
      container_class='add-items-form'
      showSnackbar={showSnackbar}
    >

      <div className="paper-title font-semibold">
        {
          is_edit ? 'Edit policy' : 'Add new policy'
        }
      </div>
      <div className="input-row bg-white shadow-sm rounded-3">
        <div>
          <label className="form-label required"> Policy No </label>
          <input
            type="text"
            className="form-control font-medium"
            name="policy_no"
            value={details.policy_no}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Company </label>
          <select
            className="form-select font-medium"
            name="company_id"
            value={details.company_id}
            onChange={handleChangeDetails}
          >
            <option value=''> Select Company </option>
            {
              companies.map((company) => {
                return (
                  <option key={company._id} value={company._id}> {company.name} </option>
                )
              })
            }
          </select>
        </div>

        <div>
          <label className="form-label required"> Issue Date </label>
          <DatePicker
            className="w-100"
            customInput={<CustomDateInput />}
            placeholderText="Select Date"
            selected={moment(details.issue_date).isValid() ? moment(details.issue_date).toISOString() : null}
            onChange={(date) => handleDateChange("issue_date", date)}
            dateFormat='dd-MM-yyyy'
            renderCustomHeader={
              (props) => (
                <CustomDateHeader
                  {...props}
                />
              )
            }
          />
        </div>

        <div>
          <label className="form-label required"> Expiry Date </label>
          <DatePicker
            className="w-100"
            customInput={<CustomDateInput />}
            placeholderText="Select Date"
            selected={moment(details.expiry_date).isValid() ? moment(details.expiry_date).toISOString() : null}
            onChange={(date) => handleDateChange("expiry_date", date)}
            dateFormat='dd-MM-yyyy'
            renderCustomHeader={
              (props) => (
                <CustomDateHeader
                  {...props}
                />
              )
            }
          />
        </div>

        <div>
          <label className="form-label required"> Authorized By </label>
          <input
            type="text"
            className="form-control font-medium"
            name="authorized_by"
            value={details.authorized_by}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Coverage Value </label>
          <input
            type="text"
            className="form-control font-medium"
            name="coverage_value"
            value={details.coverage_value}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Downtime Period </label>
          <input
            type="text"
            className="form-control font-medium"
            name="downtime_period"
            value={details.downtime_period}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Address </label>
          <input
            type="text"
            className="form-control font-medium"
            name="address"
            value={details.address}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Business Activity </label>
          <input
            type="text"
            className="form-control font-medium"
            name="business_activity"
            value={details.business_activity}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Waiting Period </label>
          <input
            type="text"
            className="form-control font-medium"
            name="waiting_period"
            value={details.waiting_period}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Policy Deductible </label>
          <input
            type="text"
            className="form-control font-medium"
            name="policy_deductible"
            value={details.policy_deductible}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Territorial Scope </label>
          <input
            type="text"
            className="form-control font-medium"
            name="territorial_scope"
            value={details.territorial_scope}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Law and Jurisdiction </label>
          <input
            type="text"
            className="form-control font-medium"
            name="conditions"
            value={details.conditions}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Period of Insurance </label>
          <input
            type="text"
            className="form-control font-medium"
            name="law_and_jurisdiction"
            value={details.law_and_jurisdiction}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label required"> Cloud Provider </label>

          <div>

            {
              CLOUD_PROVIDERS_ARR.map((cloud_provider, ind) => {
                return (
                  <div className="form-check form-check-inline" key={`CLOUD-PROVIDER-${ind}`}>
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={cloud_provider}
                        onChange={() => handleChangeCloudProvider(cloud_provider)}
                        checked={details.cloud_providers.includes(cloud_provider)}
                      />

                      {cloud_provider}
                    </label>
                  </div>
                )
              })
            }
          </div>
        </div>
        
        {
          details.cloud_providers.includes(CLOUD_PROVIDERS.AMAZON_AWS) &&
          <div>
            <label className="form-label required"> Amazon AWS Regions </label>
            <div className="regions-container">
              {
                AMAZON_REGIONS.map((region, ind) => {

                  const region_name = region.label;
                  const key = `AMAZON_REGION_${ind + 1}`;
                  const regions_details_key = "aws_regions";

                  const regions = details[regions_details_key];

                  const is_checked = regions.includes(region_name)

                  if (region.is_region_name) {
                    return (
                      <div
                        key = {key}
                        className="region-place-name d-flex align-items-center"
                      >
                        {region_name}
                      </div>
                    )
                  } else {
                    return (
                      <label
                        key = {key}
                        className={`region-label d-flex align-items-center ${is_checked ? 'checked' : ''}`}
                      >
                        <input
                          type="checkbox"
                          checked = {is_checked}
                          onChange={() => handleChangeCloudProviderRegion(regions_details_key, region_name)}
                        />
                        {region_name}
                      </label>
                    )
                  }
                })
              }
            </div>
          </div>
        }

        {
          details.cloud_providers.includes(CLOUD_PROVIDERS.GOOGLE_GCP) &&
          <div>
            <label className="form-label required"> Google GCP Regions </label>
            <div className="regions-container">
              {
                GOOGLE_REGIONS.map((region, ind) => {

                  const region_name = region.label;
                  const key = `GOOGLE_GCP_REGION_${ind + 1}`;
                  const regions_details_key = "google_regions";

                  const regions = details[regions_details_key]

                  const is_checked = regions.includes(region_name)

                  if (region.is_region_name) {
                    return (
                      <div
                        key = {key}
                        className="region-place-name d-flex align-items-center"
                      >
                        {region_name}
                      </div>
                    )
                  } else {
                    return (
                      <label
                        key = {key}
                        className={`region-label d-flex align-items-center ${is_checked ? 'checked' : ''}`}
                      >
                        <input
                          type="checkbox"
                          checked = {is_checked}
                          onChange={() => handleChangeCloudProviderRegion(regions_details_key, region_name)}
                        />
                        {region_name}
                      </label>
                    )
                  }
                })
              }
            </div>
          </div>
        }

        {
          details.cloud_providers.includes(CLOUD_PROVIDERS.MICROSOFT_AZURE) &&
          <div>
            <label className="form-label required"> Microsot Azure Regions </label>
            <div className="regions-container">
              {
                MICROSOFT_REGIONS.map((region, ind) => {

                  const region_name = region.label;
                  const key = `MICROSOFT_AZURE_REGION_${ind + 1}`;
                  const regions_details_key = "microsoft_regions";

                  const regions = details[regions_details_key]

                  const is_checked = regions.includes(region_name)

                  if (region.is_region_name) {
                    return (
                      <div
                        key = {key}
                        className="region-place-name d-flex align-items-center"
                      >
                        {region_name}
                      </div>
                    )
                  } else {
                    return (
                      <label
                        key = {key}
                        className={`region-label d-flex align-items-center ${is_checked ? 'checked' : ''}`}
                      >
                        <input
                          type="checkbox"
                          checked = {is_checked}
                          onChange={() => handleChangeCloudProviderRegion(regions_details_key, region_name)}
                        />
                        {region_name}
                      </label>
                    )
                  }
                })
              }
            </div>
          </div>
        }

        <div className="d-flex full-width justify-content-end">
          <button
            className="btn background-primary text-white"
            onClick={handleClickSave}
            disabled={is_saving}
          >
            {is_saving ? "Saving . . ." : "Save"}
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default AddPolicy;