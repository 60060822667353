import axios from "axios";
import { api_base_url } from "./config";

const policies = {};

policies.addPolicy = async (data) => {
  const url = `${api_base_url}/policies/add`;

  try{
    const res = await axios({
      url : url,
      method : "POST",
      data : data
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

policies.getPolicies = async (skip, limit) => {
  const url = `${api_base_url}/policies/list`

  try{
    const res = await axios({
      url : url,
      params : {
        skip : skip,
        limit : limit
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

policies.getPolicy = async (id) => {
  const url = `${api_base_url}/policies/get/${id}`

  try{
    const res = await axios({
      url : url
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

policies.updatePolicy = async (id, data) => {
  const url = `${api_base_url}/policies/update/${id}`

  try{
    const res = await axios({
      url : url,
      method : 'PUT',
      data : {
        data : data
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

policies.deletePolicy = async (id) => {
  const url = `${api_base_url}/policies/delete/${id}`

  try{
    const res = await axios({
      url : url,
      method : "DELETE"
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

export default policies;