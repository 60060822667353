import { useEffect, useState } from "react";
import Wrapper from "../Components/Wrapper"
import CompanyService from "../services/company";
import PoliciesService from "../services/policies";
import BillService from "../services/bills";
import { useParams, useNavigate } from "react-router-dom";
import CustomDateInput from "../Components/CustomDateInput";
import CustomDateHeader from "../Components/CustomDateHeader";
import DatePicker from "react-datepicker";
import moment from "moment";

const blank_details_obj = {
  invoice_no : '',
  invoice_date : '',
  due_date : '',
  policy_id : '',
  company_id : '',
  agency_id : '',
  invoice_amount : ''
}
const AddBill = ({
  showSnackbar
}) => {

  const [details, set_details] = useState(blank_details_obj);
  const [companies, set_companies] = useState([]);
  const [policies, set_policies] = useState([]);
  const [is_saving, set_is_saving] = useState(false);
  const [is_edit, set_is_edit] = useState(false);

  const navigate = useNavigate();

  const handleChangeDetails = (e) => {
    set_details({
      ...details,
      [e.target.name] : e.target.value
    })
  }

  useEffect(() => {
    getCompanies();
    getPolicies();
    // eslint-disable-next-line
  }, [])

  const params = useParams();
  const bill_id = params.id;

  useEffect(() => {
    if(bill_id){
      set_is_edit(true);

      getBill();
    }
    // eslint-disable-next-line
  }, [bill_id]);

  const getBill = async () => {
    // eslint-disable-next-line
    const [res, error] = await BillService.getBill(bill_id);

    if(res){

      const data_obj = res.data.data;

      const details_obj = {
        invoice_no : data_obj.invoice_no,
        invoice_date : data_obj.invoice_date,
        due_date : data_obj.due_date,
        policy_id : data_obj.policy_id,
        company_id : data_obj.company_id,
        agency_id : data_obj.agency_id,
        invoice_amount : data_obj.invoice_amount
      }

      set_details(details_obj);
    }
  }

  const getCompanies = async () => {

    // eslint-disable-next-line
    const [res, error] = await CompanyService.getCompanies(null, null);
    if(res){
      set_companies(res.data.data)
    }
  }

  const getPolicies = async () => {

    // eslint-disable-next-line
    const [res, error] = await PoliciesService.getPolicies(null, null);
    if(res){
      set_policies(res.data.data)
    }
  }

  const navigateToBillsPage = () => {
    navigate('/bills');
  }

  const handleClickSave = async () => {
    set_is_saving(true);
    if(is_edit){
      const _details = {...details};
      delete _details._id;

      // eslint-disable-next-line
      const [res, error] = await BillService.updateBill(bill_id, _details);
      
      if(error){
        showSnackbar('Error while saving bill !', 'danger')
      }else{
        showSnackbar('Bill updated successfully !', 'success')
      }
      set_is_saving(false);
      setTimeout(() => {
        navigateToBillsPage();
      }, 1000)
    }else{
      // eslint-disable-next-line
      const [res, error] = await BillService.addBill(details);
      
      if(error){
        showSnackbar('Error while saving bill !', 'danger')
      }else{
        showSnackbar('Bill generated successfully !', 'success');
        set_details(blank_details_obj);
      }
      set_is_saving(false);
      setTimeout(() => {
        navigateToBillsPage();
      }, 1000);
    }
  }

  const handleDateChange = (key, value) => {
    
    set_details({
      ...details,
      [key] : value
    })
  }

  return(
    <Wrapper
      container_class='add-items-form'
      showSnackbar = {showSnackbar}
    >

      <div className="paper-title font-semibold">
        Add new bill
      </div>
      <div className="input-row bg-white shadow-sm rounded-3">
        <div>
          <label className="form-label"> Invoice No </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "invoice_no"
            value={details.invoice_no}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Invoice Date </label>
          <DatePicker
            className="w-100"
            customInput={<CustomDateInput />}
            placeholderText="Select Date"
            selected={moment(details.invoice_date).isValid() ? moment(details.invoice_date).toISOString() : null}
            onChange={(date) => handleDateChange("invoice_date", date)}
            dateFormat='dd-MM-yyyy'
            renderCustomHeader={
              (props) => (
                <CustomDateHeader
                  {...props}
                />
              )
            }
          />
        </div>

        <div>
          <label className="form-label"> Due Date </label>
          <DatePicker
            className="w-100"
            customInput={<CustomDateInput />}
            placeholderText="Select Date"
            selected={moment(details.due_date).isValid() ? moment(details.due_date).toISOString() : null}
            onChange={(date) => handleDateChange("due_date", date)}
            dateFormat='dd-MM-yyyy'
            renderCustomHeader={
              (props) => (
                <CustomDateHeader
                  {...props}
                />
              )
            }
          />
        </div>

        <div>
          <label className="form-label"> Policy No </label>

          <select
            className="form-select font-medium"
            name = "policy_id"
            value={details.policy_id}
            onChange={handleChangeDetails}
          >
            <option value=''> Select Policy No </option>
            {
              policies.map((policy) => {
                return(
                  <option key={policy._id} value={policy._id}> {policy.policy_no} </option>
                )
              })
            }
          </select>
        </div>

        <div>
          <label className="form-label"> Company </label>
          <select
            className="form-select font-medium"
            name = "company_id"
            value={details.company_id}
            onChange={handleChangeDetails}
          >
            <option value=''> Select Company </option>
            {
              companies.map((company) => {
                return(
                  <option key={company._id} value={company._id}> {company.name} </option>
                )
              })
            }
          </select>
        </div>

        <div>
          <label className="form-label"> Invoice Amount </label>
          <input
            type="text"
            className="form-control font-medium"
            name = "invoice_amount"
            value={details.invoice_amount}
            onChange={handleChangeDetails}
          />
        </div>

        <div className="d-flex full-width justify-content-end">
          <button
            className="btn background-primary text-white"
            onClick={handleClickSave}
            disabled = {is_saving}
          >
            {is_saving ? "Saving . . ." : "Save"}
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default AddBill;