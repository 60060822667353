import { useState } from "react";

const Switch = ({
  checked
}) => {

  const [value, set_value] = useState(checked);
  
  return(
    <label
      className="switch"
    >
      <input
        type="checkbox"
        className="d-none"
        checked = {value}
        onChange={(e) => set_value(e.target.checked)}
      />

      <span className="custom-checkbox"></span>
    </label>
  )
}

export default Switch;